import React from "react";

import Loading from "../../../component/Loading";
import Modal from "../../../component/Modal";
import MapRender from "../../dashboard/_/map_block/Map";

function Map({ location, zoomLevel, isModal, modalClose }) {
  return (
    <>
      <Modal isShow={isModal} onClose={modalClose}>
        {/* <Loading loading={true} disP={true} type="spinner" /> */}
        <MapRender location={location} zoomLevel={zoomLevel} />
      </Modal>
    </>
  );
}

export default Map;
