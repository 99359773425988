import axios from "axios";

import { call, callAsync } from "./action";
import { dateFormat2, dateFormatFull, dateFormatFullZero } from "./formatter";
import { translatedComponents } from "../config/const";
import { TAKS_IMPORT } from "../config/lang";

import { getStore } from "../util/redux/store";

const me = getStore().getState().me;
export function maptoSelect2Objects(items, entity) {
  if (!Array.isArray(items)) return;
  items.forEach((item, idx) => {
    let label = item?.name || "";

    if (entity === "merchandiser") label = item.first_name + " " + item.last_name;
    if (entity === "merchandisers") label = item.first_name + " " + item.last_name;
    if (entity === "supervisor") label = item.first_name + " " + item.last_name;
    if (entity === "created_by") label = item.first_name + " " + item.last_name;
    if (entity === "point" || entity === "points") label = item.name + ", " + item.locality.name;
    if (entity === "component") {
      label = translatedComponents?.[item.name] || item.name;
    }

    items[idx] = { ...item, value: item.id, label };
    if (entity === "component") {
      items[idx] = { ...item, value: item.id, label, name: label };
    } else {
      items[idx] = { ...item, value: item.id, label };
    }
  });

  return items;
}

export function valueObject(value, SET) {
  let r = SET.find(o => {
    return o.value === value;
  });
  return r ? { value: value, label: r.label } : null;
}

export function valueObjectMulti(values, SET) {
  let list = [];
  let r = null;

  if (values && values.length > 0) {
    values.map(value => {
      r = SET.find(o => {
        return o.value === value;
      });
      if (r) list.push(r);
    });
  }

  return list;
}

export function objectToValues(objects) {
  return objects.map(i => i.value);
}

export function dateChange(oldDate, days) {
  var date = new Date(oldDate);
  var b = date.getDate() + days;
  return date.setDate(b);
}

export function dateChangeFormat(oldDate, days) {
  return dateFormat2(dateChange(oldDate, days));
}

export function dateChangeFormatFull(oldDate, days) {
  return dateFormatFull(dateChange(oldDate, days));
}

export function createLastDate() {
  let start = new Date();
  start = start.setDate(start.getDate() - 14);
  start = dateFormat2(start);
  let end = new Date();
  return { start, end };
}

export function filterUrl(entity, entityFilter) {
  const excludeKeys = ["start", "end", "task_status", "createdDateFrom", "createdDateTo", "searchText", "newFilter", "filters", "isChange"];
  let url = "";
  let isData = false;
  if (entityFilter) {
    for (const [key, value] of Object.entries(entityFilter)) {
      if (!excludeKeys.includes(key)) {
        if (entity === "visits/photos_from_reports") {
          isData = value?.toString().length > 0 ? value : false;
          if (["tag", "brand", "subcategory"].includes(key) && isData) {
            url += `&${key}=${value.name}`;
          } else if (key === "product" && isData) {
            url += `&${key}=${value.code}`;
          } else if (key === "products" && isData) {
            if (Array.isArray(value)) {
              url += `&${key}=${value.map(el => el.code).join()}`;
            }
          } else if (key === "point__networks" && isData) {
            if (Array.isArray(value)) {
              url += `&${key}=${value.map(el => el.id).join()}`;
            }
          } else if ((key === "users" && isData) || (key === "points" && isData)) {
            if (Array.isArray(value)) {
              url += `&${key}=${value.map(el => el.id).join()}`;
            }
          } else if (isData) {
            if (Array.isArray(value)) {
              url += `&${key}=${value.map(el => el.name).join()}`;
            } else {
              url += `&${key}=${value}`;
            }
          }
        } else if (entity === "merchandiser") {
          url += `?${key}=${value}`;
        } else if (entity === "visit") {
          let selfKey = key;
          if (key === "date_range" && value) url;
          // if (key === "supervisor" && value) selfKey = "supervisors";
          if (Array.isArray(value) && key != "date_range") {
            url += `&${selfKey}=${value.map(el => el.id).join()}`;
          } else if (value && key != "date_range") {
                   url += `&${selfKey}=${value}`;
                 }
        } else {
          if (value) {
            url += `&${key}=${value}`;
          }
        }
      }
    }
    // if (entityFilter.createdDateFrom && entityFilter.createdDateTo) {
    //   let createdDateFrom = entityFilter.createdDateFrom ? dateFormat2(entityFilter.createdDateFrom) : "";
    //   let createdDateTo = entityFilter.createdDateTo ? dateFormat2(dateChange(entityFilter.createdDateTo, 1)) : "";
    //
    //   url += `&created__range=${createdDateFrom},${createdDateTo}`;
    // }
    if (entityFilter.createdDateFrom) {
      let createdDateFrom = entityFilter.createdDateFrom ? dateFormat2(entityFilter.createdDateFrom) : "";
      url += `&created__gte=${createdDateFrom}`;
    }
    if (entityFilter.createdDateTo) {
      let createdDateTo = entityFilter.createdDateTo ? dateFormat2(dateChange(entityFilter.createdDateTo, 1)) : "";
      url += `&created__lte=${createdDateTo}`;
    }

    if (entity !== "answers/photos_on_review") {
      if (entityFilter.start && entity === "visit") {
        url += `&start__gte=${dateFormatFullZero(entityFilter.start)}`;
      }
      if (entityFilter.end && entity === "visit") {
        url += `&end__lte=${dateFormatFullZero(entityFilter.end)}`;
      }
      if (entityFilter.start && entity !== "visit") {
        url += `&start__gte=${dateFormat2(entityFilter.start)}`; // === moment(entityFilter.start).format("YYYY-MM-DD");
      }
      if (entityFilter.end && entity !== "visit") {
        url += `&end__lte=${dateFormat2(dateChange(entityFilter.end, 1))}`;
      }
    }

    if (entity === "answers/photos_on_review" && entityFilter.start && entityFilter.end) {
      let start = dateFormat2(entityFilter.start);
      let end = dateFormat2(dateChange(entityFilter.end, 1));

      url += "&created__range=" + start + "," + end;
    }

    if (entity === "task") {
      if (entityFilter.task_deadline) {
        url += `&is_expired=${entityFilter.task_deadline}`;
      }
      if (entityFilter.task_negative) {
        url += `&with_negative_answers=${entityFilter.task_negative}`;
      }

      if (!!entityFilter.task_status) {
        url += `&${entityFilter.task_status}=true`;
      }
      if (!!entityFilter.searchText) {
        url += `&search=${entityFilter.searchText}`;
      }
    }
  }

  return url;
}

export function validateReviewTask(type, post) {
  let errors = {};

  if (!post.title) {
    errors.title = translate(TAKS_IMPORT, "need_title_task_msg", me.language);
  }
  if (!post.template) {
    errors.template = translate(TAKS_IMPORT, "need_choose_task_msg", me.language);
  }
  if (!post.point) {
    errors.template = translate(TAKS_IMPORT, "need_choose_point_msg", me.language);
  }

  return { validate: Object.values(errors).length === 0, errors };
}

export function urlToBlob(imgUrl, cb) {
  axios({
    method: "get",
    url: imgUrl,
    headers: {
      "Content-Type": "image/jpeg",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  }).then(r => {
    var reader = new FileReader();
    reader.readAsDataURL(r.data);
    reader.onloadend = function() {
      var base64data = reader.result;
      cb(base64data);
    };
  });
}

function get_url_extension(url) {
  return url
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
}

export function modifyBase(imageUrl, blob) {
  return blob.replace("data:image/jpeg;base64,", "data:image/jpg;base64,");
}
export const translate = (group, name, lang) => {
  name = group[name] && group[name][lang] ? group[name][lang] : null;
  return name;
};

export const translateDynamic = (startDateString, group, lang, author) => {
  const startDate = new Date(startDateString);
  const now = new Date();
  const elapsedMilliseconds = now - startDate;

  const seconds = Math.floor(elapsedMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  let unit, value;
  if (seconds < 60) {
    unit = "seconds";
    value = seconds;
  } else if (minutes < 60) {
    unit = "minutes";
    value = minutes;
  } else if (hours < 24) {
    unit = "hours";
    value = hours;
  } else if (days < 30) {
    unit = "days";
    value = days;
  } else if (months < 12) {
    unit = "months";
    value = months;
  } else {
    unit = "years";
    value = years > 1 ? "more than 1 year" : "1 year";
  }

  const translatedUnit = translate(group, unit, lang);
  const translatedStart = translate(group, "point_started_title", lang);
  const translatedAgo = translate(group, "point_agoby_title", lang);
  return `${translatedStart} ${value} ${translatedUnit} ${translatedAgo} ${author}`;
};

export function urlToIdPhoto(imgUrl, owner, cb) {
  call({ method: "POST", url: "images/convert_image_to_base64/", post: { image: imgUrl } }, object => {
    const base64 = modifyBase(imgUrl, object.image);

    call({ method: "POST", url: "images/", post: { owner: owner, image: base64 } }, image => {
      if (image && image.id) {
        cb(image);
      } else {
        console.log(image);
      }
    });
  });
}

export const urlToIdPhotoAsync = async (imgUrl, owner, cb) => {
  let imgId = null;

  await callAsync({ method: "POST", url: "images/convert_image_to_base64/", post: { image: imgUrl } }, async object => {
    const base64 = modifyBase(imgUrl, object.image);

    await callAsync({ method: "POST", url: "images/", post: { owner, image: base64 } }, image => {
      if (image?.id) {
        cb(image);
        imgId = image?.id;
      } else {
        console.log(image);
      }
    });
  });
};

export function urlToBase(imagepath) {
  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  toDataUrl(imagepath, function(myBase64) {
    console.log("myBase64", myBase64); // myBase64 is the base64 string
  });
}

export const warningMessages = {
  fieldIsRequired: translate(TAKS_IMPORT, "empty_field_msg", me?.language ? me.language : "en"),
};

export const fieldIsRequired = (message = warningMessages.fieldIsRequired) => {
  return message;
};

export const getCurrentUser = () => {
  const data = localStorage.getItem("state");
  const user = JSON.parse(data);
  return user.me.role === "supervisor_manager" ? true : false;
};
