import React, { useEffect } from "react";
import moment from "moment";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";
import { useHistory } from "react-router-dom";

import Loading from "../../../component/Loading";
import { dataTableConfig } from "../../../config/config";
import { VISIT_PAGE } from "../../../config/lang";

import { call } from "../../../util/action";
import { openSingleVisitTaskRow } from "../../../util/formatter";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
function Tasks({ data }) {
  const [state, setState] = React.useState({ visits: "", loading: false });
  const history = useHistory();
  const statusOrder = {
    "In Progress": 1,
    "Completed": 2,
    "Not Completed": 3,
  };

  //Function for sorting  an array
  const sortDataByStatus = data => {
    if (!data) return;
    return data.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
  };

  useEffect(() => {
    setState({ loading: true });
    let url = `visits/${data.id}/tasks_in_visit/`;

    call({ method: "GET", url }, response => {
      setState({ visits: response, loading: false });
    });
  }, [data]);

  const getType = item => {
    if (!item) return;
    return `<span class="task-type-name">${item}</span>`;
  };

  const getTaskAnswerStatus = answers => {
    if (!answers) return;
    let status = null;

    answers.forEach(answer => {
      status = answer.boolean_answer
        ? translate(VISIT_PAGE, "task_tab_answer_status_yes_label", me.language)
        : answer.boolean_answer === false
        ? translate(VISIT_PAGE, "task_tab_answer_status_no_label", me.language)
        : null;
    });

    return status;
  };

  const getTaskAnswerText = answers => {
    if (!answers) return;

    return `<p class="visit-task-answer-text">${answers[0].answer}</p>`;
  };

  const getTaskTags = answers => {
    if (!answers) return;
    let tags = null;

    const setTextTagWrapper = clientTags => {
      return clientTags[0].tags.map(tag => `<p class="visit-task-tag rounded">${tag}</p>`).join("");
    };

    answers.forEach(answer => {
      tags = answer.client_and_tags?.length > 0 ? setTextTagWrapper(answer.client_and_tags) : "";
    });
    return tags;
  };

  const getDeadline = item => {
    if (!item) return;

    let newDate = new Date(item);
    const currentDate = new Date();
    const date = moment(newDate).format("DD/MM/yyyy");
    return `<span class="visit-task-date ${newDate < currentDate ? "deadline" : ""}">${date}</span>`;
  };

  const getTaskStatus = item => {
    if (!item) return;
    const str = item.toLowerCase();
    const result = str.replace(/ /g, "-");
    return `<span class="visit-task-status visit-${result}">${item === "In Progress" ? "On review" : item}</span>`;
  };

  const getTaskRowStatusClass = item => {
    if (!item.status) return;
    const str = item.status.toLowerCase();
    const result = str.replace(/ /g, "-");
    return result;
  };

  const onRowClick = task => {
    openSingleVisitTaskRow(task.id, history);
  };

  return (
    <>
      <div className="visit-task-tab-wrapper">
        {state.visits ? (
          <BootstrapTable
            data={sortDataByStatus(state.visits)}
            version="4"
            striped
            hover
            bordered={false}
            pagination={false}
            search={false}
            multiColumnSearch={true}
            columnFilter={false}
            options={{ ...dataTableConfig, onRowClick: onRowClick }}
            className="themed-main-table-container"
            trClassName={row => `visit-task-tab-item ${getTaskRowStatusClass(row)}`}
            tableHeaderClass="primary">
            <TableHeaderColumn dataFormat={getType} dataField="template_name" isKey={true} width="180px">
              {translate(VISIT_PAGE, "task_tab_type_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="title" width="280px">
              {translate(VISIT_PAGE, "task_tab_task_name_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" width="280px">
              {translate(VISIT_PAGE, "task_tab_info_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getDeadline} dataField="end" width="100px">
              {translate(VISIT_PAGE, "task_tab_deadline_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="image_count" width="146px">
              {translate(VISIT_PAGE, "task_tab_count_photo_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getTaskStatus} dataField="status" width="120px">
              {translate(VISIT_PAGE, "task_tab_status_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getTaskAnswerStatus} dataField="answers" width="100px">
              {translate(VISIT_PAGE, "task_tab_answer_status_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getTaskAnswerText} dataField="answers" width="100px">
              {translate(VISIT_PAGE, "task_tab_answer_comment_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getTaskTags} dataField="answers" width="100px">
              {translate(VISIT_PAGE, "task_tab_answer_tags_label", me.language)}
            </TableHeaderColumn>
          </BootstrapTable>
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default Tasks;
