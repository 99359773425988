import React, { useEffect } from "react";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";

import Loading from "../../../component/Loading";
import { dataTableConfig } from "../../../config/config";
import { VISIT_PAGE } from "../../../config/lang";

import { call } from "../../../util/action";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
function Monitoring({ data }) {
  const [state, setState] = React.useState({ monitoring: "", loading: false });
  useEffect(() => {
    setState({ loading: true });

    let url = `visits/${data.id}/price_monitoring_in_visit/`;

    call({ method: "GET", url }, response => {
      setState({ monitoring: response.results, loading: false });
    });
  }, [data]);

    const getImgObj = src => {
      if (!src) return;
      return `<a href="${src}" target="_blank"><img src="${src}" class="monitoring-product-img" alt="Click to open in new window" /></a>`;
    };

  return (
    <>
      <div className="visit-monitoring-tab-wrapper">
        {state.monitoring ? (
          <BootstrapTable
            data={state.monitoring}
            version="4"
            striped
            hover
            bordered={false}
            pagination={false}
            search={false}
            multiColumnSearch={true}
            columnFilter={false}
            options={{ ...dataTableConfig }}
            className="themed-main-table-container"
            trClassName="visit-monitoring-tab-item"
            tableHeaderClass="primary">
            <TableHeaderColumn dataField="product_name" isKey={true} width="80px">
              {translate(VISIT_PAGE, "monitoring_tab_pname_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="product_code" width="180px">
              {translate(VISIT_PAGE, "monitoring_tab_pcode_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price" width="100px" dataAlign="center">
              {translate(VISIT_PAGE, "monitoring_tab_price_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="sale_price" width="100px" dataAlign="center">
              {translate(VISIT_PAGE, "monitoring_tab_sale_price_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getImgObj} dataField="photo_url" columnClassName={"monitoring-product-img-col"} width="240px">
              {translate(VISIT_PAGE, "monitoring_tab_photo_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="comment" width="180px">
              {translate(VISIT_PAGE, "monitoring_tab_comment_label", me.language)}
            </TableHeaderColumn>
          </BootstrapTable>
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default Monitoring;
