import React, { useEffect } from "react";

import Loading from "../../../component/Loading";
import NoVisitImg from "../../../../assets/image/no-visit-photo.jpg";
import { VISIT_PAGE } from "../../../config/lang";

import { call } from "../../../util/action";
import { dateFormat } from "../../../util/formatter";
import { isImage } from "../../../util/is-valid-url";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
function LogEvents({ data }) {
  const [state, setState] = React.useState({ logs: "", loading: false });
  useEffect(() => {
    setState({ loading: true });

    let url = `visits/${data.id}/events/`;

    call({ method: "GET", url }, response => {
      setState({ logs: response.results, loading: false });
    });
  }, [data]);

  const isObjectEmpty = obj => {
    return obj && Object.keys(obj).length === 0;
  };

  const getUserName = item => {
    let name = item.user.first_name ? item.user.first_name : "";
    let lastName = item.user.last_name ? item.user.last_name : "";
    return name + " " + lastName;
  };

  return (
    <>
      <div className="visit-events-tab-wrapper">
        {state.logs ? (
          state.logs.map(item => {
            return (
              <div className="visit-events-item" key={item.id}>
                <div className="photo-user">
                  <img src={isImage(item?.user?.photo) ? item.user.photo : NoVisitImg} alt={getUserName(item)} className="w-62 visit-item-author-avatar" />
                  <span className={`user-status ${item.user.is_active ? "active" : "inactive"}`}></span>
                </div>
                <div className="event-user-data">
                  <p className="event-user-event-time">{dateFormat(item.timestamp)}</p>
                  <p className="visit-events-item-author-wrapper">
                    <span className="visit-events-item-author">{getUserName(item)} </span>
                    <span className="visit-events-item-action">{item.additional_info.event}</span>
                    {item.action === "create" && item.changes?.new_task ? (
                      <a className="visit-events-item-task-link" href={`/#/task/${item.changes?.new_task[1]}`} target="_blank">
                        {item.changes?.new_task[1]}
                      </a>
                    ) : null}
                  </p>
                  <div className="visit-events-statistic-list">
                    {!isObjectEmpty(item.additional_info.statistic)
                      ? Object.keys(item.additional_info.statistic).map((key, index) => {
                          const name =
                            key === "monitoring"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_monitoring_label", me.language)
                              : key === "photos_in_answers"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_photos_in_answers_label", me.language)
                              : key === "photos_in_reports"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_photos_in_reports_label", me.language)
                              : key === "reports_done"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_reports_done_label", me.language)
                              : key === "tasks_done"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_tasks_done_label", me.language)
                              : key === "tasks_not_done"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_tasks_not_done_label", me.language)
                              : key === "answers_not_supervised"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_answers_not_supervised_label", me.language)
                              : key === "answers_supervised"
                              ? translate(VISIT_PAGE, "monitoring_tab_event_answers_supervised_label", me.language)
                              : "";

                          return (
                            <p key={item + index}>
                              <span className="visit-events-item-statistic-name">{name}</span>
                              <span className="visit-events-item-statistic-value">{item.additional_info.statistic[key]}</span>
                            </p>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default LogEvents;
